<template>
  <div class="filters">
    <v-container fluid>
      <v-row class="d-flex align-end">
        <!-- Modo de Visualização -->

        <v-col class="d-flex" cols="12" sm="2">
          <v-select
            :items="viewModes"
            v-model="viewMode"
            label="Modo de Visualização"
            :rules="rulesText"
            @change="typeOfLead"
          ></v-select>
        </v-col>

        <!-- DATA PICKER -->
        <v-col class="d-flex" cols="12" sm="3">
          <v-dialog
            ref="dialog"
            v-model="menu"
            :return-value.sync="date"
            :close-on-content-click="false"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Selecionar intervalo de datas"
                prepend-icon="event"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker v-model="date" scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.dialog.save(date);
                  configureDates(date);
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <!-- PRODUTO -->
        <v-col class="d-flex" cols="12" sm="3">
          <v-select
            :items="produtos"
            item-text="name"
            item-value="id"
            v-model="produto"
            label="Produto"
            attach
            :rules="rulesText"
          >
          </v-select>
        </v-col>

        <!-- CONSULTOR -->

        <v-col class="d-flex" cols="12" sm="3">
          <v-select
            :items="consultores"
            item-text="name"
            item-value="id"
            v-model="consultor"
            label="Consultor"
            attach
            chips
            multiple
            :rules="rulesText"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 2">
                <span>
                  {{ item.name }}
                </span>
                <v-spacer></v-spacer>
              </v-chip>
              <span v-if="index == 2" class="grey--text caption">
                {{
                  `+${consultor.length - 2} other${
                    consultor.length - 2 > 1 ? "s" : ""
                  }`
                }}
              </span>
            </template>
          </v-select>
        </v-col>

        <!-- BTN GERAR -->
        <v-col class="d-flex align-self-center" cols="12" sm="1">
          <v-spacer></v-spacer>
          <v-fade-transition>
            <v-btn
              dark
              @click="generateReport()"
              :disabled="
                produto == '' || consultor == '' || viewMode == '' || date == ''
              "
            >
              Gerar
            </v-btn>
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "FiltersReport",
  data: () => ({
    items: [],
    date: [],
    menu: false,
    modal: false,
    menu2: false,

    produtos: [],
    produto: "",

    consultores: [],
    consultor: "",

    viewModes: ["Diário", "Semanal", "Mensal", "Produto", "Consultor"],
    viewMode: "",

    //Rules:
    rulesText: [(v) => !!v || "Todos os filtros são necessários"],
  }),
  computed: {
    dateRangeText() {
      if (this.date.length > 0) return this.date.join(" a ") || "";
      else return "";
    },
    resultados() {
      if (this.produto == "" || this.produto == null) {
        return [];
      } else {
        let res = this.results.filter((e) => e.process_id == this.produto);

        return res;
      }
    },

    dateInt() {
      let res = this.date;
      res = res.sort((a, b) => {
        var c = new Date(a);
        var d = new Date(b);
        return c - d;
      });

      return res;
    },
  },
  mounted() {
    this.collectPageData();
  },
  methods: {
    deselectedItem(id) {
      this.consultores = this.consultores.filter((e) => e != id);
    },
    async collectPageData() {
      if (this.$store.state.consultores.length == 0) {
        await this.$store.dispatch("collect_consultores");
      }

      await this.$store.dispatch("products");

      //let url = "https://app.reorganiza.pt/public/api/products";

      //const prods = await this.call_api_get(url);
      this.produtos = this.$store.state.leadProducts;

      /*url = "https://app.reorganiza.pt/public/api/consultores";

      const users = await this.call_api_get(url);*/
      this.consultores = this.$store.state.consultores;
    },
    typeOfLead() {
      this.$store.commit("SET_TYPE_OF_VIEW", this.viewMode);
    },
    async generateReport() {
      let filters = {
        start_date: this.dateInt[0] || null,
        end_date: this.dateInt[1] || null,
        produto: this.produto[0] || null,
        consultores: this.consultor || null,
        modo: this.viewMode || null,
      };

      /* for (let i in filters) {
        if (filters[i] == null) {
          alert("Todos os filtros são necessários");

          return;
        } else if (i == "consultores" && filters[i].length == 0) {
          alert("Todos os filtros são necessários");

          return;
        } else if (i == "produto" && filters[i].length == 0) {
          alert("Todos os filtros são necessários");

          return;
        } else if (i == "consultores" && filters[i].length == 0) {
          alert("Todos os filtros são necessários");

          return;
        }
      }*/

      this.$emit("collect-leads", filters);
    },
    configureDates: function (dates) {
      //Caso vier data vazia - Set primeiro dia do mês
      let arrayDates = dates;

      if (arrayDates[0].length == 0) {
        let secondDate = arrayDates[1].split("-");
        arrayDates.splice(0, 1, `${secondDate[0]}-${secondDate[1]}-01`);
      }

      if (new Date(arrayDates[0]) > new Date(arrayDates[1])) {
        let day2 = arrayDates[0];
        let day1 = arrayDates[1];

        arrayDates = [day1, day2];
      }

      this.dates = arrayDates;

      this.$store.commit("SET_DATES_REPORT", arrayDates);
    },
  },
};
</script>

<style scoped>
.filters {
  margin: 20px 40px 0px 296px;
}

.input-datas {
  margin-top: -2px;
  font-size: 14px;
}
</style>